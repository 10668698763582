import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import { PageBreadcrumb } from "../../components/shared"
import {
  SectionRatings,
  SectionRealEstate,
  SectionIncome,
  SectionEducation,
  SectionEmployment,
} from "../../components/county"
import FeatherIcon from "feather-icons-react"

import { Container, Row, Col, Card, CardBody } from "reactstrap"

import health from "../../assets/images/hero/health.svg"
import termLife from "../../assets/images/hero/term-life.svg"
import familyHealth from "../../assets/images/hero/family-health.svg"

export const query = graphql`
  query CountyInfoQuery($county: String!) {
    strapiGlobal {
      siteName
      favicon {
        localFile {
          publicURL
        }
      }
      defaultSeo {
        robots
        metaDescription
        metaTitle
        keywords
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiZipInfo(filter: { county: { eq: $county } }) {
      totalCount
      edges {
        node {
          id
          bus_ratio
          county
          fips_code
          four_bedroom_rent
          lat
          lng
          median_income
          one_bedroom_rent
          oth_ratio
          res_ratio
          state
          studio_rent
          three_bedroom_rent
          two_bedroom_rent
          zip
          rating {
            bachelor_degree_rate
            business_rating
            business_score
            college_degree_rate
            edu_rating
            edu_score
            educated_rate
            employment_mean
            employment_rate
            employment_rating
            graduate_degree_rate
            high_school_diploma_rate
            hotness_rank
            hotness_score
            income_rating
            income_score
            median_days_on_market
            median_days_on_market_mm
            median_days_on_market_yy
            median_dom_vs_us
            median_income
            median_listing_price
            median_listing_price_mm
            median_listing_price_vs_us
            overall_rating
            median_listing_price_yy
            overall_score
            poverty_rate
            poverty_rating
            poverty_score
            tax_rate
            total_employed
            total_population
          }
        }
      }
    }
  }
`

const County = ({ data }) => {
  const { strapiGlobal, allStrapiZipInfo } = data
  let countyData = allStrapiZipInfo.edges.find(r => r.node.rating)
  if (!countyData) {
    countyData = allStrapiZipInfo.edges[0]
  }
  let allCountyData = allStrapiZipInfo.edges
  // TODO : we need to add more SEO metatag,
  // like google maps image url?
  const seo = {
    metaTitle:
      countyData.node.county +
      ", " +
      countyData.node.state.toUpperCase() +
      " Market Insights",
    metaDescription:
      "Understand real estate market insights for " +
      countyData.node.county +
      ", " +
      countyData.node.state,
  }

  const [categories, setCategories] = useState([
    { icon: "star", title: "Ratings", link: "#ratings" },
    { icon: "home", title: "Real Estate", link: "#realEstate" },
    { icon: "dollar-sign", title: "Rent Data", link: "#rentData" },
  ])

  return (
    <Layout seo={seo} strapiGlobal={strapiGlobal}>
      <PageBreadcrumb
        page={{
          source: "counties",
          showBreadcrumb: true,
        }}
      >
        <Container>
          <Row className="mt-5 pt-5 justify-content-center">
            <Col lg={12}>
              <div className="title-heading text-center">
                <h1 className="heading title-dark text-white mb-3">
                  {countyData.county}
                </h1>
              </div>
              <Row className="mt-5 pt-2 w-50 mx-auto">
                {categories.map((category, key) => (
                  <Col key={key} lg={4} md={6} xs={12} className="mt-4 pt-2">
                    <Card className="explore-feature border-0 rounded text-center bg-white ml-5">
                      <CardBody>
                        <div className="icon rounded-circle shadow-lg d-inline-block">
                          <FeatherIcon
                            icon={category.icon}
                            className="avatar avatar-sm text-dark"
                          />
                        </div>
                        <div className="content mt-3">
                          <Link to={category.link}>
                            <h6 className="mb-0 title text-dark">
                              {category.title}
                            </h6>
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </PageBreadcrumb>

      <section className="section">
        {countyData.node.rating && <SectionRatings county={countyData.node} />}
        <SectionRealEstate
          county={countyData.node}
          allCountyData={allCountyData}
        />
        {/* <SectionPropertyTax
          county={countyData.county}
          state={countyData.state}
        /> */}
      </section>
    </Layout>
  )
}

export default County
